import React from "react";
import homeCSS from "./HomeSG.module.css";
import CodeIMG from './CodeIMG.png';
import Parallax from 'react-rellax'
import { HiDownload } from 'react-icons/hi';
const HomeSG = () =>{
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch('Siddhant Gupta Resume.pdf').then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = '';
            alink.click();
        })
    })
}
return(
  <>
  <Parallax  speed={-3} >
  	<div data-aos="zoom-out-up" className={homeCSS.fixedTop} speed={-2} style={{ transform: 'rotate(35deg)' }} />
    </Parallax>
    <div className={homeCSS.wrapper}>
      <Parallax  speed={6} >
      <div className={homeCSS.title} data-aos="zoom-in-up">Hi, My name is </div></Parallax>
      <Parallax  speed={5} >
        <div  data-aos="fade-right" className={homeCSS.name}>Siddhant Gupta</div>
        </Parallax>
      <Parallax  speed={3} >
      <div  data-aos="fade-up-right" className={homeCSS.intro}>Web Developer</div>
        <div data-aos="flip-down" className={homeCSS.btn} onClick={onButtonClick}>
        <span className={homeCSS.btnTitle}> Download CV </span>
         <span className={homeCSS.dIcon}>
          <HiDownload />
         </span>
        </div>
      </Parallax>
    </div>
    </>  
);
}

export default HomeSG;