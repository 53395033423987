import React, {useEffect} from "react";
import skillDisCSS from './SkillDisplay.module.css';
import { FaJava , FaNodeJs , FaHtml5 } from 'react-icons/fa';
import { GrReactjs } from 'react-icons/gr';
import { ImCss3 } from 'react-icons/im';
import { SiJavascript , SiMongodb , SiPython } from 'react-icons/si';
import AOS from 'aos';
import "aos/dist/aos.css";

const SkillDisplay = (props) =>{
    useEffect(()=>{
        AOS.init({duration:1000});
      } , []);
    return(
        <>
        <div data-aos="fade-right"  className={skillDisCSS.wrapper}>
            <SiJavascript className={skillDisCSS.skillIcon} />
            <div className={skillDisCSS.title} > JavaScript </div>
        </div>
        <div data-aos="fade-right" className={skillDisCSS.wrapper}>
            <FaJava  className={skillDisCSS.skillIcon} />
            <div className={skillDisCSS.title} >Java </div>
        </div>
        <div data-aos="fade-right" className={skillDisCSS.wrapper}>
            <SiPython className={skillDisCSS.skillIcon} />
            <div className={skillDisCSS.title} > Python </div>
        </div>
        <div data-aos="fade-right" className={skillDisCSS.wrapper}>
            <GrReactjs className={skillDisCSS.skillIcon} />
            <div className={skillDisCSS.title} > React JS</div>
        </div>
        <div data-aos="fade-right" className={skillDisCSS.wrapper}>
            <FaHtml5 className={skillDisCSS.skillIcon} />
            <div className={skillDisCSS.title} > HTML 5 </div>
        </div>
        <div data-aos="fade-right" className={skillDisCSS.wrapper}>
            <ImCss3 className={skillDisCSS.skillIcon} />
            <div className={skillDisCSS.title} > CSS </div>
        </div>
        <div data-aos="fade-right" className={skillDisCSS.wrapper}>
            <FaNodeJs className={skillDisCSS.skillIcon} />
            <div className={skillDisCSS.title} > Node JS </div>
        </div>
        <div data-aos="fade-right"  className={skillDisCSS.wrapper}>
            <SiMongodb className={skillDisCSS.skillIcon} />
            <div className={skillDisCSS.title} >Mongo DB</div>
        </div>
       </> 
    );
}

export default SkillDisplay;