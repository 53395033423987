import React from "react";
import emailCSS from './EmailSG.module.css';
const EmailSG = () =>{
 return(
  
   <div className={emailCSS.wrapper}>
   <div className={emailCSS.text}>
      <h5> <a href='mailto:guptasiddhant214@gmail.com'>
         guptasiddhant214@gmail.com
     </a></h5>
     </div>
   <div className={emailCSS.line}></div>
 </div>

 );
}

export default EmailSG;