import React,{useEffect} from "react";
import aboutCSS from "./AboutSG.module.css";
import sgImg from "../../img/mePIC1.png"
import AOS from 'aos';
import "aos/dist/aos.css";
import { BsFillPersonFill} from 'react-icons/bs';

import SkillDisplay from "./SkillDisplay/SkillDisplay";



// 


const AboutSG = () =>{
  useEffect(()=>{
    AOS.init({duration:2000});
  } , []);
    return(
  <div className={aboutCSS.wrapper}>
    {/* <BsFillPersonFill  className={aboutCSS.aboutIcon}/>
    <div className={aboutCSS.title}> About Me</div> */}
    <div className={aboutCSS.aboutWrapper}>
    <div className={aboutCSS.data}>
      <div data-aos="fade-down-right" className={aboutCSS.text}>

      A highly innovative individual with a keen interest in developing
         and deploying new projects on World Wide Web
        Ability to integrate market needs into technical solutions. 
        Looking to begin a career as an entry-level software developer with a reputable 
        company that is focused on innovation.<br /><br />

        Here are a few technologies I’ve been working with recently:
      </div>
      <div className={aboutCSS.skill}>
        <SkillDisplay />
       </div>
    </div>
    
       <div data-aos="zoom-in-up" className={aboutCSS.sgIMG}>
      <img src={sgImg} alt="Siddhant Gupta" />
      </div>
     </div>  
 </div>
    );
}

export default AboutSG;