
import './App.css';
import FooterContainer from './Components/Footer/FooterContainer';
import Navbar from './Components/Navbar/Navbar'
import AboutSG from './Pages/ABOUT/AboutSG';
import Certificate from './Pages/Certificate/Certificate';
import EmailSG from './Pages/EMAIL/EmailSG';
import HomeSG from './Pages/HOME/HomeSG';
import SocialMediaSG from './Pages/SOCIALMEDIA/SocialMediaSG';

function App() {
  return (
    <div className="App">
		<div className="wrapAPP">
      {/* <Navbar /> */}
      <div className="displayAPP">
        <div className="SocialMedia" > <SocialMediaSG /> </div>
         <div className="Home" > 
           <HomeSG />
           <AboutSG /> 
          {/* <Certificate /> */}
        </div>
       <div className="Email" > <EmailSG /> </div>
     </div>
        <FooterContainer />
    </ div>
    
    </div>
  );
}

export default App;
