import React from "react";
import socialMediaCSS from './SocialMediaSG.module.css';

import { BsGithub } from 'react-icons/bs';
import { FaInstagram , FaLinkedinIn } from 'react-icons/fa';

const SocialMediaSG = () =>{
 return(
   <div data-aos="fade-down"className={socialMediaCSS.wrapper}>
     <div  className={socialMediaCSS.line}></div>
     <div className={socialMediaCSS.sMIcons}>
     <a href="https://github.com/guptaSiddhant-JS" target="blank" ><BsGithub /></a>
     <a href="https://www.instagram.com/micku.sg/" target="blank" ><FaInstagram /></a>
     <a href="https://www.linkedin.com/in/siddhant-gupta-9826a71a7/" target="blank"><FaLinkedinIn /></a>
     </div>
     
   </div>
 );
}

export default SocialMediaSG;