import React from "react";
import footerCSS from "./FooterContainer.module.css"
import sgSig from "../../img/signature.png"

const FooterContainer = () => {
    return(
        <div className={footerCSS.wrapper}>
        <div className={footerCSS.sgSignature}> 
        <img src={sgSig} />
        </div>
        <div className={footerCSS.title}>Designed and build by Siddhant Gupta</div>     
        </div>
        
    );
}

export default FooterContainer;
